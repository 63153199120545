import React, { Fragment } from "react"
import Helmet from "../components/Helmet"

const Four0FourPage = () => (
  <Fragment>
    <Helmet title="Not Found" descr="" admin={true} />
    <div>Page not found.</div>
  </Fragment>
)
export default Four0FourPage
